/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/
import React from "react";
import * as XLSX from "xlsx";

export default class SheetJSApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
    };
    this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }
  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[Number(window.prompt("Sheet Number?"))];
      const ws = wb.Sheets[wsname];
      console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const cols = make_cols(ws["!ref"]);
      const database = require('./data.json');
      data.forEach((r,i) => {
        let isFullRow = false;
        let y = {
          "SECTION": null,
          "BILL": null,
          "ITEM_NO": null,
          "UNIT": null,
          "QUANTITY": null,
          "RATE": null
        };
        cols.forEach(c =>{
          if(c.name === 'A' && r[c.key] && r[c.key] !== "COUNTER"){
            isFullRow = true
          }
          if(isFullRow && c.name === "C") y.SECTION = r[c.key];
          if(isFullRow && c.name === "D") y.BILL = r[c.key];
          if(isFullRow && c.name === "F") y.ITEM_NO = r[c.key];
          if(isFullRow && c.name === "J") y.UNIT = r[c.key];
          if(isFullRow && c.name === "K") y.QUANTITY = r[c.key];
          if(isFullRow && c.name === "L") {
            y.RATE = database.filter(x => x.SECTION === y.SECTION && x.BILL === y.BILL && x.ITEM_NO === y.ITEM_NO && x.UNIT === y.UNIT)[0]?.RATE;
            r[c.key] = y.RATE;
          };
          if(isFullRow && c.name === "M") {
            const amount = y.RATE ? y.RATE * Number(y.QUANTITY) : null;
            r[c.key] = amount;
          }
        });
      });
      /* Update state */
      this.setState({ data, cols });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  handleRatesFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const database = require('./data.json');
      

      wb.SheetNames.forEach(wsname => {
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const cols = make_cols(ws["!ref"]);
        data.forEach((r,i) => {
          let isFullRow = false;
          let y = {
            "SECTION": null,
            "BILL": null,
            "ITEM_NO": null,
            "UNIT": null,
            "RATE": null
          };
          cols.forEach(c =>{
            if(c.name === 'A' && r[c.key] && r[c.key] !== "COUNTER"){
              isFullRow = true
            }
            if(isFullRow && c.name === "C") y.SECTION = r[c.key];
            if(isFullRow && c.name === "D") y.BILL = r[c.key];
            if(isFullRow && c.name === "F") y.ITEM_NO = r[c.key];
            if(isFullRow && c.name === "J") y.UNIT = r[c.key];
            if(isFullRow && c.name === "L") {
              y.RATE = r[c.key];
              const rateIndex = database.findIndex(x => x.SECTION === y.SECTION && x.BILL === y.BILL && x.ITEM_NO === y.ITEM_NO && x.UNIT === y.UNIT);
              if (rateIndex > -1)
                database[rateIndex] = y;
              else
                database.push(y);
            };
          });
        });
      });
      const element = document.createElement("a");
      const textFile = new Blob([JSON.stringify(database)], {type: 'text/plain'}); //pass data from localStorage API to blob
      element.href = URL.createObjectURL(textFile);
      element.download = "data.json";
      document.body.appendChild(element); 
      element.click();
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }
  exportFile() {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(this.state.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "boq.xlsx");
  }
  render() {
    return (
      <DragDropFile handleFile={this.handleFile}>
        <div className="row">
          <div className="col-xs-12">
            <DataInput handleFile={this.handleRatesFile} fileType={"Import New Rates Data:"} />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-xs-12">
            <DataInput handleFile={this.handleFile} fileType={"Import BOQ File:"} />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-xs-12">
            <button
              hidden={!this.state.data.length}
              className="btn btn-success"
              onClick={this.exportFile}
            >
              Export Priced BOQ File
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <OutTable data={this.state.data} cols={this.state.cols} />
          </div>
        </div>
      </DragDropFile>
    );
  }
}

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          <label htmlFor="file">{this.props.fileType}</label>
          <input
            type="file"
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
  render() {
    const headers = [];
    const data = {};
    this.props.data.forEach((r,i) => {
      let isHeaderFound = false;
      let isFullRow = false;
      this.props.cols.forEach(c =>{
        if(r[c.key] === 'COUNTER') isHeaderFound = true;
        if(isHeaderFound) headers.push(r[c.key]);
        else if(c.name === 'A' && r[c.key]){
          isFullRow = true
          data[i] = [];
        }
        if('ABCDEFGHIJKLMNO'.includes(c.name) && isFullRow) data[i].push(r[c.key]);
      });
    });
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.length > 0 ? <th>COL</th> : null}
              {headers.map(c => (
                <th key={c}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(data).map((r, i) => (
              <tr key={i}>
                <td>{i}</td>
                {r.map(c => (
                  <td key={c}>{c}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
